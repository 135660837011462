.example > div {
    margin-bottom: 3rem;
}

.example > div:last-of-type {
    margin-bottom: 1rem;
}

h3 {
    font-weight: 500;
}