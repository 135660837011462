@font-face {
  font-family: AzeretMono;
  src: local('AzeretMono'), url('../fonts/AzeretMono.ttf');
}

body {
  background-color: #1c1d1f;
}

.Game {
  text-align: center;
  color: white;
  font-family: AzeretMono, monospace;
  font-variant-ligatures: none;
  font-weight: 300;
  outline: none; /* Prevent ugly dotted outline. Side effect of auto-focussing the document div. */
  min-height: 100vh;
  min-height: -webkit-fill-available; /* Fix to show the footer above the toolbar in iOS Safari */
  position: relative;
}

.header {
  font-size: calc(10px + 2vmin);
  display: grid;
  grid-template-columns: 15% 70% 15%;
  justify-items: center;
}

.header .help {
  font-size: 2rem;
  margin: 1rem 1rem 1rem auto;
  cursor: pointer;
}

.header h2 {
  font-weight: 500;
  margin: 1rem;
  border-bottom: 2px dashed white;
  grid-column-start: 2;
}

.guess {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  text-transform: uppercase;
  font-size: 1.5rem;
}

.button {
  border: none;
  border-radius: 0;
  color: white;
  background-color: #1f49b5;
  text-transform: uppercase;
  padding: 0.75rem 1.25rem;
  margin: 0.5rem;
  -webkit-appearance: none;
  font-size: 1rem;
  vertical-align: middle;
}

.button.warning {
  background-color: #8f0101;
}

.button:enabled:hover {
  cursor: pointer;
  box-shadow: inset 0 0 0 2px white;
}

.button:disabled {
  background-color: grey;
  cursor: default;
  border: none;
}

.guessInput {
  border: none;
  border-radius: 2px;
  outline: none;
  text-transform: uppercase;
  text-align: center;
  caret-color: transparent;
  width: 1.1ch;
  padding: 8px 11px;
  margin: 0px 3px;
  font-size: 1.25rem;
  font-family: AzeretMono, monospace;
}

.guessInput:focus {
  background-color: #1f49b5;
  color: white;
}

.message {
  margin-top: 0.5rem;
}

.invalidGuess {
  color: red;
}

.duplicateGuess {
  color: orange;
}

.textBlock {
    max-width: 450px;
    margin: 15px auto;
    padding: 0 15px;
}

.fadeOut {
  animation-delay: 1.5s;
  animation-duration: 1.5s;
  animation-name: fadeOut;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    max-height: 200px;
  }
  to {
    opacity: 0;
    max-height: 0;
  }
}

.flashMessageOff {
  display: none;
}

.guessInputTile {
  border: 1px solid white;
  color: white;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  padding: 9px 12px;
  margin: 0px 3px;
  font-size: 1.25rem;
  user-select: none;
  display: inline-block;
  width: 1ch;
  height: 1.5rem;
  vertical-align: middle;
  background-color: #101113;
}

.guessInputTile.selected {
  border-color: #1f49b5;
  color: white;
}
