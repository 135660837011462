.colourPicker {
  padding: 10px 10px 8px 10px;
  cursor: pointer;
  margin: 10px 8px 0 10px;
  display: inline-block;
}

.colourPicker.present {
  background-color: orange;
}

.colourPicker.positionKnown {
  background-color: green;
}

.colourPicker.unknown {
  background-color: grey;
}

.colourPicker.playerRuledOut {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.3);;
}

.colourPicker.active {
  border-bottom: 5px solid white;
}