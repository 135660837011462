.pinGroup {
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
}


.pinGroup > .pin:nth-child(2) {
  animation-delay: 0.1s;
}

.pinGroup > .pin:nth-child(3) {
  animation-delay: 0.2s;
}

.pinGroup > .pin:nth-child(4) {
  animation-delay: 0.3s;
}

.pinGroup > .pin:nth-child(5) {
  animation-delay: 0.4s;
}

.pin {
  margin: 0 0.15rem;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: inline-block;
  animation-duration: 1s;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.skipAnimation {
  animation: none;
  opacity: 1;
}

.correctPin {
  background-color: green;
}

.partialPin {
  background-color: orange;
}

.incorrectPin {
  background-color: white;
}
