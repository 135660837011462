.key {
    padding: 9px 8px;
    margin: 0px 3px;
    border-radius: 2px;
    border: 1px solid white;
    user-select: none;
    text-transform: uppercase;
    font-size: 1.5rem;
    cursor: pointer;
    background-color: #101113;
}

.key.backspace {
    padding: 9px 16px;
    margin: 0px 3px 0px 3px;
    border-radius: 2px;
    border: 1px solid white;
    user-select: none;
    font-size: 1.5rem;
    cursor: pointer;
}

@media only screen and (max-width: 390px) {
    .key {
        font-size: 1.2rem;
        margin: 0px 2px;
    }
}

.key.knownAndPartial {
  background: linear-gradient(45deg, orange 50%, green 50%);
}

.key.impossible {
    border: 1px solid red;
    color: red;
}

.key.positionKnown {
    background-color: green;
}

.key.present {
    background-color: orange;
}

.key.eliminated {
    background-color: none;
    border: 1px solid red;
    color: red;
    opacity: 0.3;
}

.key.playerRuledOut {
    background-color: none;
    opacity: 0.15;
}

.key.unknown {
    background-color: grey;
}

.key.pressed, .delete.pressed {
    box-shadow: inset 0 0 0 2px white;
    position: relative;
}

.key.eliminated.pressed {
    box-shadow: inset 0 0 0 2px red;
    position: relative;
}
