.letterTile {
    padding: 8px 11px;
    margin: 0px 3px;
    border-radius: 2px;
    user-select: none;
    text-transform: uppercase;
    font-size: 1.25rem;
    cursor: pointer;
    font-weight: 500;
}

.letterTile.unknown {
    background-color: grey;
}

.letterTile.positionKnown {
    background-color: green;
}

.letterTile.present {
    background-color: orange;
}

.letterTile.eliminated {
    background-color: none;
    box-shadow: inset 0 0 0 2px red;
    color: red;
    opacity: 0.3;
}

.letterTile.playerRuledOut {
    background-color: none;
    box-shadow: inset 0 0 0 2px white;
    opacity: 0.15;
}
